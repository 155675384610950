import { Ducks } from "./wasm/pkg/lucky_duck_slots_mod";

import BlackHatDuck from "./assets/BlackHatDuck.png";
import SunglassesDuck from "./assets/SunglassesDuck.png";
import RainbowDuck from "./assets/RainbowDuck.png";
import DiederikDuck from "./assets/DiederikDuck.png";
import OrangeDuck from "./assets/OrangeDuck.png";
import PlainOldDuck from "./assets/PlainOldDuck.png";
import WhiteDuck from "./assets/WhiteDuck.png";
import FlamingoDuck from "./assets/FlamingoDuck.png";

export const duckAssets: { [key in Ducks]: string | undefined } = {
  [Ducks.BlackHatDuck]: BlackHatDuck,
  [Ducks.DiederikDuck]: DiederikDuck,
  [Ducks.OrangeDuck]: OrangeDuck,
  [Ducks.RainbowDuck]: RainbowDuck,
  [Ducks.SunglassesDuck]: SunglassesDuck,
  [Ducks.PlainOldDuck]: PlainOldDuck,
  [Ducks.WhiteDuck]: WhiteDuck,
  [Ducks.FlamingoDuck]: FlamingoDuck,
};

export const duckDescriptions: {
  [key in Ducks]?: { title: string; description: string } | undefined;
} = {
  [Ducks.BlackHatDuck]: {
    title: "Blackhat Duck",
    description:
      "BlackHat Duck is the mysterious duck of the bunch. Always seen lurking in the shadows, he's got the reputation of being up to something. With his sleek black hat and enigmatic aura, the other ducks can't help but wonder if he's secretly hacking the pond's systems. He's an elusive figure, making him a prized sight for anyone who spots him.",
  },
  [Ducks.DiederikDuck]: {
    title: "Striped Duck",
    description:
      "StripedDuck is a trendsetter and fashion icon among the ducks. With his striking blue and white stripes, he exudes confidence and style. However, not all ducks in the pond agree with his choice of style, which might be the reason for his rarity. Additionally, StripedDuck is known for his adventurous spirit, often embarking on solo expeditions to explore new ponds and lakes, making sightings of him in the pond less frequent. This air of mystery, along with the debate surrounding his fashion choices, makes him a rare and treasured member of the pond.",
  },
  [Ducks.OrangeDuck]: {
    title: "Kingsday Duck",
    description:
      "Kingsday Duck is still reeling from the excitement of the recent Kingsday celebrations. Decked out in his orange outfit, he's a lively and spirited duck who loves a good party. Little did he know that this year's festivities would leave him in a three-week coma! Now that he's awake, he's ready to share his vibrant plumage and tales of his wild adventure. Though he's not the rarest duck in the pond, his infectious energy makes him a favorite among fans.",
  },
  [Ducks.RainbowDuck]: {
    title: "Rainbow Duck",
    description:
      "RainbowDuck is the advocate for equality and unity among all ducks. She embraces the colors of the rainbow and believes that every duck, no matter their hue or characteristics, should be celebrated. Her vibrant feathers are a testament to her message of love and acceptance. She may have a rocky relationship with Nyan Cat, but her commitment to spreading joy through her colorful appearance is unwavering. While she's a rare sight in the pond, her presence always brightens up the day.",
  },
  [Ducks.SunglassesDuck]: {
    title: "Sunglasses Duck",
    description:
      "SunglassesDuck is the epitome of cool. With a laid-back attitude and an unshakable sense of style, he's the duck everyone wants to befriend. He's often found basking in the sun, sporting his trademark shades, and letting the water gently carry him along. Though he may seem fairly common in the pond, he's still considered a rare find among duck enthusiasts, and those who spot him can't help but feel lucky.",
  },
  [Ducks.WhiteDuck]: {
    title: "White Duck",
    description:
      "WhiteDuck may not be too special, but her pure white feathers make her a standout among the other ducks. She's a little shy, and tends to keep to herself, but is always up for a friendly chat. She's the one the other ducks come to for advice and comfort, and she's always happy to lend an ear. Despite her beauty, WhiteDuck isn't one for showing off, preferring to blend in with the crowd rather than draw attention to herself.",
  },
  [Ducks.FlamingoDuck]: {
    title: "Flamingo Duck",
    description:
      "FlamingoDuck may have a bit of an identity crisis, but he's one of the most unique ducks in the pond. With his bright pink feathers and distinctive beak, he's definitely not your average duck. Though some of the other ducks tease him, FlamingoDuck is proud of who he is and is always looking for ways to stand out even more. RainbowDuck has taken a particular liking to him, appreciating his unique look and always sticking up for him when he's teased.",
  },
  [Ducks.PlainOldDuck]: {
    title: "Plain Old Duck",
    description:
      "PlainOldDuck may be plain, but he's dependable and steady. He's always there, day in and day out, paddling around the pond and enjoying the simple pleasures of life. Though he may not stand out in a crowd, he's the backbone of the duck community, providing a steady presence that the other ducks can always count on. He's not one for drama or excitement, but his easy-going nature and friendly demeanor make him a well-loved member of the pond.",
  },
};
