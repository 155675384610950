import React from "react";
import "./PointsModal.css";
import { duckAssets } from "./ducks";
import { Ducks } from "./wasm/pkg/lucky_duck_slots_mod";

import CloseIcon from "./assets/Close.png";
import PlaceholderDuck from "./assets/PlaceholderDuck.png";

type PointsModalProps = {
  show: boolean;
  onClose: () => any;
};

const DuckImage = ({ duck }: { duck: Ducks | string }) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <img
    className="points-duck-img"
    src={typeof duck === "string" ? duck : duckAssets[duck]}
  />
);

const PointsModal = ({ show, onClose }: PointsModalProps) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <div className="modal-title">Welcome to quackpot</div>
          <div className="modal-close-btn" onClick={onClose}>
            <img src={CloseIcon} alt="Close" />
          </div>
        </div>
        <div className="points-modal-content">
          <div className="points-description">
            Welcome to Lucky Duck's quacky slot machine! To get your payout, you
            need to have at least three of the same ducks lined up next to each
            other on your reels. The more neighbouring ducks you have, the
            higher your score will be! Each duck has its own point value, which
            you can find in the table. The point value will be multiplied by the
            amount of neighbouring ducks. If you're lucky enough to hit five
            striped ducks in a row, you'll hit the quackpot and win the special
            prize! Don't underestimate the plain old duck though, they might not
            be worth much on their own, but they can sure help you out in a
            streak!
          </div>
          <div className="tables">
            <div className="points-table">
              <div className="points-table-header">Base duck payout</div>
              <div className="points-table-row">
                <DuckImage duck={Ducks.PlainOldDuck} />
                <span>10</span>
              </div>
              <div className="points-table-row">
                <DuckImage duck={Ducks.WhiteDuck} />
                <span>20</span>
              </div>
              <div className="points-table-row">
                <DuckImage duck={Ducks.SunglassesDuck} />
                <span>30</span>
              </div>
              <div className="points-table-row">
                <DuckImage duck={Ducks.FlamingoDuck} />
                <span>40</span>
              </div>
            </div>
            <div className="points-table">
              <div className="points-table-header">Base duck payout</div>

              <div className="points-table-row">
                <DuckImage duck={Ducks.BlackHatDuck} />
                <span>50</span>
              </div>
              <div className="points-table-row">
                <DuckImage duck={Ducks.OrangeDuck} />
                <span>75</span>
              </div>
              <div className="points-table-row">
                <DuckImage duck={Ducks.RainbowDuck} />
                <span>100</span>
              </div>
              <div className="points-table-row">
                <DuckImage duck={Ducks.DiederikDuck} />
                <span>150</span>
              </div>
            </div>

            <div className="points-table">
              <div className="points-table-header">Multipliers</div>
              <div className="multiplier-table-row">
                <div className="duck-row">
                  <DuckImage duck={PlaceholderDuck} />
                  <DuckImage duck={PlaceholderDuck} />
                  <DuckImage duck={PlaceholderDuck} />
                </div>
                <span>3x</span>
              </div>
              <div className="multiplier-table-row">
                <div className="duck-row">
                  <DuckImage duck={PlaceholderDuck} />
                  <DuckImage duck={PlaceholderDuck} />
                  <DuckImage duck={PlaceholderDuck} />
                  <DuckImage duck={PlaceholderDuck} />
                </div>
                <span>4x</span>
              </div>
              <div className="multiplier-table-row">
                <div className="duck-row">
                  <DuckImage duck={PlaceholderDuck} />
                  <DuckImage duck={PlaceholderDuck} />
                  <DuckImage duck={PlaceholderDuck} />
                  <DuckImage duck={PlaceholderDuck} />
                  <DuckImage duck={PlaceholderDuck} />
                </div>
                <span>5x</span>
              </div>
              <div className="multiplier-table-row">
                <div className="duck-row">
                  <DuckImage duck={Ducks.DiederikDuck} />
                  <DuckImage duck={Ducks.DiederikDuck} />
                  <DuckImage duck={Ducks.DiederikDuck} />
                  <DuckImage duck={Ducks.DiederikDuck} />
                  <DuckImage duck={Ducks.DiederikDuck} />
                </div>
                <span>10x + flag</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointsModal;
