import { motion } from "framer-motion";
import { useState } from "react";

import { duckAssets } from "./ducks";
import { Ducks } from "./wasm/pkg/lucky_duck_slots_mod";
import { useAudio } from "./hooks/useAudio";

import "./Reel.css";
import stopSound from "./assets/sounds/static_sounds_stop.mp3";

type ReelProps = {
  reelIdx: number;
  target: Ducks;
  setCurrentModalDuck: (duck: Ducks) => void;
  partOfCombo: boolean;
  rollIdx: number;
  onAnimationComplete?: () => void;
};

const DUCK_COUNT = Object.keys(Ducks).length / 2;
const BASE_ANIMATION_DURATION = 0.75;
const PER_REEL_DELAY = 0.4;

const duckPositionInReel = (idx: number) => idx * (360 / DUCK_COUNT);

const Reel = ({
  reelIdx,
  target,
  setCurrentModalDuck,
  partOfCombo,
  rollIdx,
  onAnimationComplete,
}: ReelProps) => {
  // const [isCombo, setIsCombo] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isPlayingComboAnimation, setIsPlayingComboAnimation] = useState(false);
  const { toggle } = useAudio(stopSound);

  return (
    <motion.div className="Reel">
      {[...Array.from(Array(DUCK_COUNT).keys())].map((idx) => (
        <motion.img
          key={idx}
          src={duckAssets[idx as Ducks]}
          style={{
            originZ: -250,
            rotateX: -duckPositionInReel(idx),
          }}
          animate={{
            rotateX: -(360 * 4 * rollIdx) - duckPositionInReel(idx - target),
          }}
          transition={{
            type: "tween",
            duration: BASE_ANIMATION_DURATION + reelIdx * PER_REEL_DELAY,
          }}
          onClick={() => target === idx && setCurrentModalDuck(idx)}
          onAnimationStart={() => {
            setIsAnimating(true);
            setIsPlayingComboAnimation(false);
          }}
          onAnimationComplete={() => {
            toggle();
            setIsAnimating(false);
            setTimeout(
              () => setIsPlayingComboAnimation(partOfCombo),
              BASE_ANIMATION_DURATION + (5 - reelIdx) * PER_REEL_DELAY * 1000
            );
            onAnimationComplete?.();
          }}
          className={`${target === idx && !isAnimating ? "Active-reel" : ""} ${
            isPlayingComboAnimation && target === idx ? "Combo-reel" : ""
          }`}
        />
      ))}
    </motion.div>
  );
};

export default Reel;
