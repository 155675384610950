import React, { useState, useEffect } from "react";
import { CountUp } from "use-count-up";

import init, {
  Ducks,
  roll_slot_machine as rollSlotMachine,
  get_points as getPoints,
  get_quackpot_prize as getQuackpotPrize,
} from "./wasm/pkg/lucky_duck_slots_mod";
import { useAudio } from "./hooks/useAudio";

import DuckModal from "./DuckModal";
import Reel from "./Reel";

import winSound from "./assets/sounds/static_sounds_win.mp3";
import "./App.css";
import PointsModal from "./PointsModal";

// Used to determine combo positions (little hacky but it will do)
const getMostCommonDuck = (slotResult: Uint8Array) => {
  return Array.from(slotResult)
    .sort(
      (a, b) =>
        slotResult.filter((v) => v === a).length -
        slotResult.filter((v) => v === b).length
    )
    .pop();
};

function App() {
  const { toggle } = useAudio(winSound);

  const [rollIdx, setRollIdx] = useState(0);
  const [rollAnimationComplete, setRollAnimationComplete] = useState(true);
  const [currentSlotResult, setCurrentSlotResult] = useState<Uint8Array>();
  const [currentSlotStreaks, setCurrentSlotStreaks] = useState<boolean[]>(
    Array(5).fill(false)
  );
  const [prevPoints, setPrevPoints] = useState<number>(0);
  const [points, setPoints] = useState<number>(0);
  const [newPoints, setNewPoints] = useState<number>(0);
  const [currentModalDuck, setCurrentModalDuck] = useState<Ducks | null>(null);
  const [showPointsModal, setShowPointsModal] = useState<boolean>(true);

  const rollSlotMachineAndSetResult = async () => {
    setRollAnimationComplete(false);

    let slotResult = await rollSlotMachine?.();

    if (slotResult) {
      setRollIdx(rollIdx + 1);
      setCurrentSlotResult(slotResult);

      const roll_points = getPoints?.(slotResult);
      if (roll_points) {
        setNewPoints(points + roll_points);

        const mostCommonDuck = getMostCommonDuck(slotResult);

        setCurrentSlotStreaks(
          Array.from(slotResult).map((r) => r === mostCommonDuck)
        );
      } else {
        setCurrentSlotStreaks(Array(5).fill(false));
      }

      const quackpotPrize = getQuackpotPrize?.(slotResult);
      if (quackpotPrize?.includes("CTF{")) {
        console.log(
          `[🏁] Seems like you hit the quackpot, have a flag: ${quackpotPrize}`
        );
      }
    }
  };

  // Initialise WASM and do an inital roll on page load
  useEffect(() => {
    init().then(rollSlotMachineAndSetResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update points and combo after roll animation has completed
  useEffect(() => {
    if (rollAnimationComplete && points !== newPoints) {
      toggle();
      setPrevPoints(points);
      setPoints(newPoints);
    }
  }, [
    rollAnimationComplete,
    setPrevPoints,
    setPoints,
    points,
    newPoints,
    toggle,
  ]);

  return (
    <div className="App">
      <header className="App-header">
        <div className="Title">Quackpot</div>
        <div className="Points">
          <CountUp
            key={points}
            start={prevPoints}
            end={points}
            duration={5}
            isCounting
          />
          <span>points</span>
        </div>
      </header>
      <div className="App-reels">
        {[
          ...Array.from(currentSlotResult || []).map(
            (reelResult: Ducks, idx) => (
              <Reel
                key={idx}
                reelIdx={idx}
                partOfCombo={currentSlotStreaks?.[idx]}
                target={reelResult}
                setCurrentModalDuck={setCurrentModalDuck}
                rollIdx={rollIdx}
                // Call animation complete if last reel
                onAnimationComplete={
                  idx === 4 ? () => setRollAnimationComplete(true) : undefined
                }
              />
            )
          ),
        ]}
      </div>
      <button
        className={`Roll-button ${
          rollAnimationComplete ? "Roll-button-clickable" : ""
        }`}
        onClick={rollSlotMachineAndSetResult}
        disabled={!rollAnimationComplete}
      >
        {rollAnimationComplete ? "Roll" : "Hold on..."}
      </button>
      <DuckModal
        currentDuck={currentModalDuck}
        onClose={() => setCurrentModalDuck(null)}
      />
      <PointsModal
        show={showPointsModal}
        onClose={() => setShowPointsModal(false)}
      />
    </div>
  );
}

export default App;
