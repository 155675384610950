import React from "react";
import "./DuckModal.css";
import { duckAssets, duckDescriptions } from "./ducks";
import { Ducks } from "./wasm/pkg/lucky_duck_slots_mod";

import CloseIcon from "./assets/Close.png";

type DuckModalProps = {
  currentDuck: Ducks | null;
  onClose: () => any;
};

const DuckModal = ({ currentDuck, onClose }: DuckModalProps) => {
  if (currentDuck === null) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <div className="modal-title">
            {duckDescriptions[currentDuck]?.title}
          </div>
          <div className="modal-close-btn" onClick={onClose}>
            <img src={CloseIcon} alt="Close" />
          </div>
        </div>
        <div className="modal-content">
          <div className="duck-container">
            <img
              src={duckAssets[currentDuck]}
              className="duck-img"
              alt="A majestic duck"
            />
          </div>
          <div className="modal-description">
            {duckDescriptions[currentDuck]?.description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DuckModal;
